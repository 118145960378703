exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-academia-tsx": () => import("./../../../src/pages/academia.tsx" /* webpackChunkName: "component---src-pages-academia-tsx" */),
  "component---src-pages-hire-us-tsx": () => import("./../../../src/pages/hire-us.tsx" /* webpackChunkName: "component---src-pages-hire-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-internships-tsx": () => import("./../../../src/pages/internships.tsx" /* webpackChunkName: "component---src-pages-internships-tsx" */),
  "component---src-pages-subscription-confirmation-tsx": () => import("./../../../src/pages/subscription-confirmation.tsx" /* webpackChunkName: "component---src-pages-subscription-confirmation-tsx" */),
  "component---src-pages-work-tsx": () => import("./../../../src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */)
}

